import { models } from "leatherman-js";
import Status from "@/classes/Status";
import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "app-environment:delete":
      store.dispatch("sites/refreshListBySiteId", data.service.cloud_account.reference_account_id);
      store.dispatch("sites/refreshAllBySiteId", data.service.cloud_account.reference_account_id);
      break;
    case "virt-guest-cloudaccount:add":
      // eslint-disable-next-line no-case-declarations
      const addedSite = models.Site.formatLegacy(data);
      if (["development", "staging"].includes(addedSite.environmentType)) {
        store.dispatch("devSites/refreshList", { parentSiteId: addedSite.referenceAccount.id });
      } else {
        store.dispatch("sites/refreshList", { planId: addedSite.planId });
        store.dispatch("sites/refreshAll");
        store.dispatch("plans/refreshDiskSpace", addedSite.planId);
        store.dispatch("plans/refreshItem", addedSite.planId);
      }

      store.dispatch("toasts/addToast", {
        variant: "info",
        message: "Your site will be up and running soon.",
      });
      break;
    case "virt-guest-cloudaccount:delete": {
      const deletedSite = models.Site.formatLegacy(data);
      store.dispatch("sites/refreshAll");
      if (["development", "staging"].includes(deletedSite.environmentType)) {
        if (deletedSite.referenceAccount) {
          store.dispatch("devSites/refreshList", { parentSiteId: deletedSite.referenceAccount.id });
        }
      } else {
        store.dispatch("sites/refreshList", { planId: data.service_id });
        store.dispatch("plans/refreshDiskSpace", data.service_id);
        store.dispatch("plans/refreshItem", data.service_id);
      }
      const site = store.getters["sites/getItem"];
      if (site?.id) {
        store.dispatch("sites/refreshItem", site?.id);
      }
      store.dispatch("devSites/refreshListByDevSiteId", data.id);
      store.dispatch("safeHarbor/refreshSiteStagingEnvironmentsByServiceSiteId", data.id);

      // This is a hack to identify a regression site. The websocket event does not have any
      // data that pertains to the site environment to check if its regression.
      if (!deletedSite.domain.startsWith("reg-")) {
        store.dispatch("toasts/addToast", {
          variant: "info",
          message: `${deletedSite.domain} has been removed.`,
        });
      }
      break;
    }
    case "virt-guest-cloudaccount:update": {
      const updatedSite = models.Site.formatLegacy(data);

      store.dispatch("sites/refreshList", { planId: data.service_id, data });
      store.dispatch("plans/refreshDiskSpace", data.service_id);
      store.dispatch("plans/refreshItem", data.service_id);

      if (["development", "staging"].includes(updatedSite.environmentType)) {
        store.dispatch("devSites/refreshList", { parentSiteId: updatedSite.referenceAccount.id });
        store.dispatch("devSites/refreshItem", data.id);
        store.dispatch("sites/refreshListBySiteId", data.reference_account_id);
      } else {
        store.dispatch("sites/refreshListBySiteId", data.id);
        store.dispatch("sites/refreshItem", data.id);
        store.dispatch("devSites/refreshItem", data.id);
      }

      if (data.reference_account_id) {
        store.dispatch("sites/refreshItem", data.reference_account_id);
        store.dispatch("devSites/refreshList", { parentSiteId: data.reference_account_id });
      }
      break;
    }
    case "virt-guest-containergroup:update":
      store.dispatch("containers/update", data.account_id);
      if (data.type?.service === "php" || data.type?.identity === "php") {
        store.dispatch("sites/refreshAdvancedAutoScale");
      }
      break;
    case "virt-guest-containergroup:delete":
      store.dispatch("containers/refreshList");
      if (data.type?.service === "php" || data.type?.identity === "php") {
        store.dispatch("sites/refreshAdvancedAutoScale");
      }
      break;
    case "api-task:add":
      if (data.resource_scope === "virt-guest-cloudaccount") {
        if (
          data.action.includes("set-newrelic-key") ||
          data.action.includes("clear-newrelic-key")
        ) {
          store.dispatch("sites/addNewRelicUpdate", data.resource_id);
        }
      }
      break;
    case "api-task:update":
      if (data.resource_scope === "virt-guest-cloudaccount") {
        if (data.identity.includes("portal.v1.cloud-account-advanced-autoscale")) {
          store.dispatch("sites/refreshAdvancedAutoScale", { id: data.resource_id });
        }
        if (
          data.identity.includes("set-newrelic-key") ||
          data.identity.includes("clear-newrelic-key")
        ) {
          store.dispatch("sites/refreshNewRelic", data.resource_id);
          const plan = store.getters["plans/getItem"];
          const site = store.getters["sites/getItem"];
          const name = plan.isTShirt ? plan.nickname : site.domain;
          if (data.identity.includes("set-newrelic-key (failure)")) {
            store.dispatch("sites/removeNewRelicUpdate", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "error",
              message: `Something went wrong trying to enable New Relic: ${name}`,
            });
          }
          if (data.identity.includes("set-newrelic-key (success)")) {
            store.dispatch("sites/removeNewRelicUpdate", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "You have successfully enabled New Relic!",
            });
          }
          if (data.identity.includes("clear-newrelic-key (success)")) {
            store.dispatch("sites/removeNewRelicUpdate", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "You have successfully disabled New Relic!",
            });
          }
        }
      }
      if (data.action === "site:change-vanity-domain" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your site's vanity domain has been changed.",
        });
        store.dispatch("sites/refreshItem", data.resource_id);
      }
      if (data.action === "site:change-vanity-domain" && data.status === "rejected") {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "There was a problem changing your site's vanity domain.",
        });
      }
      if (data.action === "site:update-cloudflare-configuration" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your site's Performance Shield settings have been changed.",
        });
        store.dispatch("sites/cloudflare/refreshItem", data.resource_id);
      }
      if (data.action === "site:update-cloudflare-configuration" && data.status === "rejected") {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "There was a problem changing your site's Performance Shield settings.",
        });
      }
      if (data.action === "site:add-cloudflare" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your site has successfully added Performance Shield.",
        });
        store.dispatch("sites/cloudflare/refreshItem", data.resource_id);
        store.dispatch("sites/refreshListBySiteId", data.resource_id);
        store.dispatch("allSites/refreshList");
      }
      if (data.action === "site:add-cloudflare" && ["failure", "rejected"].includes(data.status)) {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "There was a problem adding your site's Performance Shield.",
        });
        store.dispatch("sites/cloudflare/refreshItem", data.resource_id);
        store.dispatch("sites/refreshListBySiteId", data.resource_id);
        store.dispatch("allSites/refreshList");
      }
      if (
        data.action === "site:add-cloudflare" &&
        ["pending", "processing"].includes(data.status)
      ) {
        store.dispatch("sites/cloudflare/setCloudflareStatus", Status.LOADING);
      }
      if (data.action === "site:remove-cloudflare" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Successfully removed Performance Shield.",
        });
        store.dispatch("sites/cloudflare/refreshItem", data.resource_id);
        store.dispatch("sites/refreshListBySiteId", data.resource_id);
        store.dispatch("allSites/refreshList");
      }
      if (
        data.action === "site:remove-cloudflare" &&
        ["failure", "rejected"].includes(data.status)
      ) {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "Failed to remove Performance Shield.",
        });
        store.dispatch("sites/cloudflare/refreshItem", data.resource_id);
      }
      if (
        data.action === "site:remove-cloudflare" &&
        ["pending", "processing"].includes(data.status)
      ) {
        store.dispatch("sites/cloudflare/setCloudflareStatus", Status.LOADING);
      }
      break;
    case "cron-options:change":
      store.dispatch("siteCron/refreshOptions");
      break;
    case "cron:change":
      store.dispatch("siteCron/refreshTasks");
      break;
    case "primary-domain:error":
      store.dispatch("toasts/addToast", {
        variant: "error",
        message: `Something went wrong trying to modify the Master Domain for ${data?.domain}.`,
      });
      break;
    case "primary-domain:change":
      store.dispatch("letsencrypt/refreshDomains");
      store.dispatch("pointerDomains/refreshList");
      break;
  }
};

export default handler;
